* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: 'Work Sans', sans-serif;
    overscroll-behavior: none;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    background: rgb(34,193,195);
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);

    /* Temporaire : sera géré en js "Get started" */
    pointer-events: none;
}

section {
    min-height: 100vh;
    max-width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 100px;
}


h5{
    text-transform: uppercase;
}

.dev{
    font-size: 20px;
    font-weight: 800;
}

ul{
    list-style-type: none;
}

h5, h2, p, ul, button{
    margin-bottom: 8px;

}

h5{
    color: #336959;
}

h4{
    margin-bottom: 8px;
}
button, .button{
    display: block;
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    font-family: 'Work Sans';
    border-radius: 10px;
    border: solid #4EA48C 2px;
    background-color: #4EA48C;
    color: rgb(235, 233, 233);
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 0;
}

.pagination{
    margin-top: 25px;
    margin-left: auto;
    color: #336959;


}

button:hover, .button:hover {
    background-color: rgb(235, 233, 233);
    color: #4EA48C;    
}


#dev, #design {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/**
 * SLIDER
**/

.slider{
    display: flex;
    transform: translateX(0);
    transition: .3s all;
}

.slide{
    width: 75vw;
    margin-right: 100vw;
    flex-shrink: 0;
    max-width: 580px;
    background-color: #FFF;
    border-radius: 8PX;
    padding: 16px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.slide p {
    line-height: 1.5;
}


.chevron.cursor {
    position: absolute;
    padding: 8px;
    background: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    z-index: 10;
    cursor: pointer;
    top: calc(50vh - 7.5px);
}
.chevron.cursor.left {
    left: 50px;
} 
.chevron.cursor.right {
    right: 50px;
} 
.chevron.cursor:hover:before {
    border-color: #4EA48C;
}

.chevron::before {
	border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 0.15em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
}

.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}

.chevron.left:before {
	/* left: 0.25em; */
	transform: rotate(-135deg);
}

.tete{
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.preview{
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-top: 35px;
    margin-right: 2px;
}

.buton-project{
    display: flex;
}

.profil{
    display: flex;
    margin-top: 8px;
}

.profil-txt{
    margin-left: 10px;
}

.project-preview{
    display: flex;
    justify-content: center; 
    align-items: center;
    gap: 10px;
}

.project-preview .preview{
    margin-top: 0;
    margin-right: 0;
}


.resume{
    text-decoration: none;
}

.resume:hover{
    color: #4EA48C;
}

#tahiti{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#tahiti h2{
    font-size: 50px;
}

#tahiti .job{
    font-size: 25px;
}

.mouse{
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 40px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  border: 2px solid #4EA48C;
  animation: intro 1s;
}

.scroll{
  display: block;
  width: 3px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #4EA48C;
  animation: finger 1s infinite;
}

#scroll{
    color: #4EA48C;
}
		
@keyframes intro {
  0%{
    opacity: 0;
    transform: translateY(40px);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes finger{
  0%{
    opacity: 1;
 }
  100%{
    opacity: 0;
    transform: translateY(20px);
  }
}

.me{
    float: float-left;
}

*::selection{
    background-color: #5cbea3;
}

.icon{
    text-decoration: none;
    color: #5cbea3;
    font-size: 30px;
}

.icon:hover{
    color: #4EA48C;
}



.rs{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
}

.graphic-design{
    transition: box-shadow .3s;
    width: 330px;
    height: 330px;
   background-color: white; 
   border-radius: 10PX;
   margin-top: 30px;
   padding: 15px;
   margin-right: 30px;

}

.didi{
    background: #faedcd;
}

.affiche{
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 8px;
}

.graphic-design:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }

.box{
    display: flex;
    flex-wrap: wrap;

}

@media screen and (max-width: 900px) {
    section {
        padding: 24px;
    }

    #tahiti{ 
        justify-content: space-evenly;
    }
    .mouse{
        display: none;
    }

    .chevron.cursor {
        width: 24px;
        height: 24px;
    }
    .chevron::before {
        height: 4px;
        width: 4px;
        left: 1px;
        top: 1px;
    }
    .chevron.cursor.left {
        left: 5px;
    } 
    .chevron.cursor.right {
        right: 5px;
    } 

    .slide{
        width: 100%;
    }

    .project-preview { 
        flex-direction: column }

}

